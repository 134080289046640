import { Component } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Redirect } from "@gatsbyjs/reach-router";
import styled from "styled-components";
import { Icons } from "../../utils/react-svg";
import { media, responsive, Opacity } from "../../utils/style";
import MagicLink from "../MagicLink";
import Container from "../Container";
import { rem, Color, Font } from "../../utils/style";
import ScrollMagicController from "../ScrollMagicController";
import Circle from "../home/Circle";
import Text from "../Text";

const MainWrapper = styled.div.attrs({
  className: "",
})`
  position: relative;

  ${media.notDesktop`
    margin-top: ${rem(24)};
  `};
`;

const BackLink = styled(MagicLink).attrs({
  className: "d-inline-block",
})`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  ${Font.circular} font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  line-height: ${rem(26)};
  vertical-align: text-bottom;
  margin-bottom: 24px;

  ${responsive.md`
    margin-bottom: 0;
  `}

  p {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }

  i {
    height: ${rem(16)};
    width: ${rem(32)};
    position: relative;
    display: inline-block;
    vertical-align: middle;

    svg {
      position: absolute;
      transform: translateY(-50%);
      width: 100%;
      top: 50%;
      left: ${rem(-3)};
      right: 0;
      bottom: 0;
    }
  }
`;

const Ingredient = styled.h1`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  font-weight: 500;
  letter-spacing: -0.5px;
  border-bottom: 2px solid ${Color.ritualBlue};
  margin-top: ${rem(24)};
  margin-bottom: 24px;
  padding-bottom: ${rem(24)};

  ${responsive.sm`
    margin-top: 0;
    font-size: ${rem(34)};
  `}

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    padding-bottom: ${rem(40)};

  `}

  ${responsive.lg`
    font-size: ${rem(66)};
    line-height: ${rem(72)};
    letter-spacing: ${rem(-2)};
  `}
`;

const PropertyRow = styled.div.attrs({
  className: "row",
})`
  &:last-of-type {
    margin-bottom: 0;

    span:last-of-type {
      margin-bottom: 0;
    }
  }

  ${responsive.sm`
    margin-left: 0px;
    margin-right: 0px;

    span {
      padding-left: 0px;
      padding-right: 0px;
    }
  `}

  ${responsive.md`
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;

    span:last-of-type {
      padding-left: 10px;
    }
  `}
`;

const Label = styled.span.attrs({
  className: "col-12 col-md-4",
})`
  line-height: ${rem(22)};
  color: rgba(20, 43, 111, ${Opacity.light});
  font-size: ${rem(16)};
  ${Font.circular} font-weight: 300;

  ${media.tablet`
  display: block;
  width: 100%;
  margin-bottom: ${rem(4)};
`};
`;

const Value = styled.span.attrs({
  className: "col-12 col-md-8",
})`
  color: ${Color.ritualBlue};
  line-height: ${rem(22)};
  font-size: ${rem(16)};
  ${Font.circular} font-weight: 500;

  ${media.tablet`
    display: block;
    width: 100%;
    margin-bottom: ${rem(16)};
  `};
`;

const InterviewLink = styled(MagicLink)`
  display: inline-block;
  color: ${Color.ritualBlue};
  font-size: ${rem(14)} !important;
  line-height: ${rem(24)} !important;
  margin-top: 24px;
  ${Font.circular} font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  border-bottom: 2px solid ${Color.ritualBlue};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    margin-top: 40px;
  `}
`;

const InfoWrapper = styled.div.attrs({
  className: "flush-left col-12 col-sm-5 order-2 order-md-1",
})`
  margin-top: 0;
`;

const ImageWrapper = styled.div.attrs({
  className:
    "flush-right col-12 col-sm-6 offset-sm-1 order-1 order-sm-2 align-self-start",
})`
  overflow: visible;
  position: relative;
`;

const IngredientImage = styled(GatsbyImage)`
  width: ${rem(570)};
  background-color: rgba(233, 238, 241, ${Opacity.light});

  ${media.notDesktop`
    width: ${rem(454)};
  `}

  ${media.tablet`
    width: ${rem(340)};
  `}

  ${media.mobile`
    width: 100%;
  `}

  @media (min-width: 450px) and (max-width: 749px) {
    max-height: ${rem(375)};
  }
`;

export default class IngredientInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      controller: null,
    };
  }

  componentDidMount() {
    this.setState({
      controller: this.controller,
    });
  }

  render() {
    const { controller } = this.state;
    const { ingredient, productSku } = this.props;
    const {
      name,
      image,
      form,
      foundIn,
      source,
      supplier,
      manufacturingLocation,
      manufacturerInterview,
      function: ingredientFunction,
    } = ingredient;

    if (!ingredient || !ingredient.name || !ingredient.image) {
      return <Redirect noThrow to={"/"} />;
    }

    return (
      <Container style={{ position: "relative", overflow: "visible" }}>
        <MainWrapper>
          <div className="row align-items-center" style={{ zIndex: 2 }}>
            <div className="flush-left col-12 col-sm-6" style={{ zIndex: 2 }}>
              <BackLink
                to="/ingredients"
                state={productSku ? { productSku } : {}}
                ariaLabel="Back to Ingredients"
              >
                <i>
                  <Icons.ArrowLeft />
                </i>
                <p>
                  <Text
                    id="ingredients.info.back"
                    defaultMessage="Back to Ingredients"
                  />
                </p>
              </BackLink>
            </div>
          </div>
          <div className="row align-items-md-center" style={{ zIndex: 1 }}>
            <InfoWrapper>
              <Ingredient>{name}</Ingredient>
              {foundIn && (
                <PropertyRow>
                  <Label>
                    <Text
                      id="ingredients.info.found-in"
                      defaultMessage="Found In"
                    />
                  </Label>
                  <Value>{foundIn}</Value>
                </PropertyRow>
              )}
              {form && (
                <PropertyRow>
                  <Label>
                    <Text id="ingredients.info.form" defaultMessage="Form" />
                  </Label>
                  <Value>{form}</Value>
                </PropertyRow>
              )}
              {source && (
                <PropertyRow>
                  <Label>
                    <Text
                      id="ingredients.info.source"
                      defaultMessage="Source"
                    />
                  </Label>
                  <Value>{source}</Value>
                </PropertyRow>
              )}
              {supplier && (
                <PropertyRow>
                  <Label>
                    <Text
                      id="ingredients.info.supplier"
                      defaultMessage="Supplier"
                    />
                  </Label>
                  <Value>{supplier}</Value>
                </PropertyRow>
              )}
              {manufacturingLocation && (
                <PropertyRow>
                  <Label>
                    <Text
                      id="ingredients.info.final-location"
                      defaultMessage="Final Location of Manufacturing"
                    />
                  </Label>
                  <Value>{manufacturingLocation}</Value>
                </PropertyRow>
              )}
              {ingredientFunction?.childMarkdownRemark.rawMarkdownBody && (
                <PropertyRow>
                  <Label>
                    <Text
                      id="ingredients.info.function"
                      defaultMessage="Function"
                    />
                  </Label>
                  <Value>
                    {ingredientFunction.childMarkdownRemark.rawMarkdownBody}
                  </Value>
                </PropertyRow>
              )}
              {manufacturerInterview && (
                <InterviewLink
                  className="-underline"
                  to={manufacturerInterview}
                  title={manufacturerInterview.title}
                >
                  <Text
                    id="ingredients.info.supplier-info"
                    defaultMessage="Get to Know Our Supplier"
                  />
                </InterviewLink>
              )}
            </InfoWrapper>
            <ImageWrapper>
              {controller && (
                <Circle controller={controller} className="right centerY" />
              )}
              <IngredientImage
                image={getImage(image)}
                loading="eager"
                alt={image.title}
                style={{
                  zIndex: 1,
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                }}
              />
            </ImageWrapper>
          </div>
        </MainWrapper>

        <ScrollMagicController
          ref={(r) => {
            r && (this.controller = r.controller);
          }}
        />
      </Container>
    );
  }
}
