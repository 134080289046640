import { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import throttle from "lodash/throttle";

// Utils
import { Color, media, atLeast, responsive } from "../../utils/style";

// Components
import Container from "../Container";
import Row from "../Row";
import MobileBannerTop from "../product/MobileBannerTop";
import MobileBannerBottom from "../product/MobileBannerBottom";

// Redux
import { useSelector } from "react-redux";

// Styled Elements
const BarWrapper = styled.div.attrs({
  id: "product-cta-bar-desktop",
  className: "product-banner",
})`
  position: fixed;
  top: 64px;
  right: 0;
  left: 0;
  z-index: 106;
  background-color: ${Color.white};
  border-bottom: 1px solid #ccc;
  transform: translateY(-200%);
  transition: transform 320ms ease-in-out, opacity 200ms ease-out;
  opacity: 0;

  &.active {
    top: initial;

    ${responsive.sm`
      transform: translateY(0);
      top: ${(p) => {
        const headerHeight = 52;
        return p.offsetFromBanner
          ? p.offsetFromBanner + headerHeight
          : headerHeight;
      }}px;
      opacity: 1;
    `}

    ${responsive.md`
      top: ${(p) => (p.offsetFromBanner ? p.offsetFromBanner + 64 : 64)}px;
    `}
  }

  ${media.mobile`
    top: initial;
    bottom: 0;
    transform: translateY(100%);
    border-top: 1px solid #ccc;
    border-bottom: 0;
  `};
`;

const BarContainer = styled(Container).attrs({
  id: "product-cta-bar-desktop_container",
})`
  padding-top: 12px;
  padding-bottom: 12px;

  ${media.tablet`
    padding-left: max(15px, env(safe-area-inset-left));
    padding-right: max(15px, env(safe-area-inset-right));
  `}

  ${media.mobile`
    padding-bottom: env(safe-area-inset-bottom);

    a {
      width: 100% !important;
      margin: 0 auto;
    }
  `};
`;

const MobileBannerContainer = styled.div`
  width: 100%;
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export default function StickyNav(props) {
  const { showTopBar = true, scrollOffset = 700, showOnFooter } = props;

  const [showStickyNav, setShowStickyNav] = useState(false);
  const [atLeastTablet, setAtLeastTablet] = useState(false);

  const offsetFromBanner = useSelector((state) => state.navigation.offset);

  function handleScroll() {
    let header = document.getElementsByTagName("header")[0];
    let footer = document.getElementsByTagName("footer")[0];
    let position = window.pageYOffset;

    if (!header || !footer) return;

    let headerHeight = header.offsetHeight;
    let footerHeight = footer.offsetHeight;
    let footerPosition = footer.offsetTop;

    let isBelowHeroAndAboveFooter =
      position >= scrollOffset - headerHeight &&
      position < footerPosition - headerHeight - footerHeight / 2 &&
      !showStickyNav;

    let isBelowFooter =
      position >= footerPosition - headerHeight - footerHeight / 2 &&
      showStickyNav;

    let isAboveHero = position < scrollOffset - headerHeight && showStickyNav;

    if (isBelowHeroAndAboveFooter) {
      setShowStickyNav(true);
    } else if (isBelowFooter && !showOnFooter) {
      setShowStickyNav(false);
    } else if (isAboveHero) {
      setShowStickyNav(false);
    }
  }

  function handleResize() {
    setAtLeastTablet(atLeast.tablet());
  }

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 500);
    const throttledHandleResize = throttle(handleResize, 400);

    window.addEventListener("scroll", throttledHandleScroll);
    window.addEventListener("resize", throttledHandleResize);

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [showStickyNav]);

  return atLeastTablet ? (
    <BarWrapper
      className={showStickyNav ? "active" : ""}
      offsetFromBanner={offsetFromBanner}
    >
      <BarContainer>
        <Row id="product-cta-bar-desktop_container_row"> {props.topNav}</Row>
      </BarContainer>
    </BarWrapper>
  ) : (
    <MobileBannerContainer className={showStickyNav ? "active" : ""}>
      {showTopBar && (
        <MobileBannerTop offsetFromBanner={offsetFromBanner}>
          {props.topNav}
        </MobileBannerTop>
      )}
      <MobileBannerBottom>{props.bottomNav}</MobileBannerBottom>
    </MobileBannerContainer>
  );
}
