import { useState, useEffect } from "react";
import styled from "styled-components";
import $ from "jquery";

// Utils
import { Color } from "../../utils/style";

// Redux
import { useSelector } from "react-redux";

const Banner = styled.div`
  position: fixed;
  top: ${(p) => p.messageBannerHeight + 4}px;
  left: 0;
  z-index: 999;

  background-color: ${Color.white};

  width: 100%;
  height: 52px;

  border-bottom: 1px solid ${Color.fadedGrey};

  display: none;

  &.compacted {
    display: flex;
  }
`;

export default function MobileBannerTop(props) {
  const [isScrollingDown, setIsScrollingDown] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const offset = useSelector((state) => state.navigation.offset);

  useEffect(() => {
    $(window).on("scroll.banner", handleScroll);

    return () => {
      $(window).off("scroll.banner", handleScroll);
    };
  }, [isScrollingDown, lastScrollTop]);

  function handleScroll() {
    // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    let st = window.pageYOffset || document.documentElement.scrollTop;

    setIsScrollingDown(st > lastScrollTop);
    setLastScrollTop(st <= 0 ? 0 : st);
  }

  return (
    <Banner
      className={isScrollingDown ? "compacted" : ""}
      messageBannerHeight={offset}
    >
      {props.children}
    </Banner>
  );
}
