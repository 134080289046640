import styled from "styled-components";

import ProductCtaBar from "../product/ProductCtaBar";
import MagicLink from "../MagicLink";
import Text from "../Text";

import { rem, Font, Color } from "../../utils/style";
import metrics from "../../utils/metrics";

// Services
import intl from "../../services/intl";

// Utils
import {
  getContentfulProductForPlan,
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";

const ProductCtaBarWrapper = styled.div`
  [data-whatintent="keyboard"] & * {
    display: none;
  }
`;

const ProductLink = styled(MagicLink)`
  ${Font.circular};
  font-size: ${rem(14)} !important;
  line-height: ${rem(24)} !important;
  border-bottom: 2px solid ${Color.ritualBlue};
`;

const StickyProductNav = (props) => {
  const {
    ctaLocation,
    scrollOffset,
    showLearnMore,
    showTopBar,
    quantity,
    sku,
  } = props;

  function trackProductCTAClick(location, e) {
    let event = {
      title: e.target.innerText,
      location: location,
    };
    metrics.track("CTA Clicked", event);
  }

  if (!sku) return null;

  const planId = getPlanIdForProductSku(sku);

  if (!planId) return null;

  const contentfulProduct = getContentfulProductForPlan(planId);
  const productAttributes = getProductAttributes(planId, null, sku);

  const { price, urlSlug } = productAttributes;
  const ctaText = intl.t("general.button-add-cart", "Add to Cart");

  return (
    <ProductCtaBarWrapper>
      <ProductCtaBar
        onClick={trackProductCTAClick.bind(this, ctaLocation)}
        product={contentfulProduct}
        quantity={quantity}
        mainText={intl.t("product.hero.button-price", "{amount} per month", {
          amount: intl.formatCurrency(price, {
            round: true,
          }),
        })}
        buttonCta={ctaText}
        productName={contentfulProduct && contentfulProduct.name}
        hasActiveLink={showLearnMore}
        showTopBar={showTopBar}
        scrollOffset={scrollOffset}
      >
        {showLearnMore && (
          <ProductLink to={`/products/${urlSlug}`} className="-underline">
            <Text id="general.learn-more" defaultMessage="Learn More" />
          </ProductLink>
        )}
      </ProductCtaBar>
    </ProductCtaBarWrapper>
  );
};

export default StickyProductNav;
