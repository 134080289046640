import { graphql } from "gatsby";
import { Component } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../utils/style";

// Components
import StickyProductNav from "../components/global/StickyProductNav";
import PageSEO from "../components/seo/Page";

import ProductCategoryCards from "../components/product/ProductCategoryCards";

import Benefits from "../components/ingredients/Benefits";
import EvidenceBased from "../components/ingredients/EvidenceBased";
import IngredientInfo from "../components/ingredients/IngredientInfo";
import IngredientInteractions from "../components/ingredients/IngredientInteractions";
import Studies from "../components/ingredients/Studies";

const Wrapper = styled.div`
  margin-top: 52px;
  overflow: hidden;

  ${responsive.md`
    margin-top: 64px;
    padding-top: 56px;
  `}
`;

const ProductCategoryCardsWrapper = styled.div`
  margin-top: 24px;

  ${responsive.md`
    margin-top: 64px;
  `}

  h3 {
    font-size: ${rem(24)};
    letter-spacing: -0.28px;
    line-height: ${rem(34)};
    text-align: center;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      letter-spacing: -1.17px;
      line-height: ${rem(54)};
      margin-bottom:  56px;
    `}
  }
`;

/* eslint-disable react/prefer-stateless-function, react/prop-types */
export default class IngredientsPageTemplate extends Component {
  constructor(props) {
    super(props);
    const { pageDescription } = props.data.contentfulIngredientsPage;
    const { name, slug, image } = props.data.contentfulIngredient;

    this.state = {
      seo: {
        pagePath: `ingredients/${slug}`,
        title: `${name}: What is ${name}? Uses, Benefits, Research | Ritual`,
        description: pageDescription,
        image: {
          url: image.share.src,
          width: image.share.width,
          height: image.share.height,
        },
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Ingredient Detail",
    });
  }

  render() {
    const { data, location } = this.props;
    const productSku = location.state ? location.state.productSku : "";

    const { contentfulIngredient } = data;

    return (
      <>
        {productSku && (
          <StickyProductNav
            sku={productSku}
            ctaLocation={"Ingredient Product Cta"}
            scrollOffset={150}
            showLearnMore={true}
            showTopBar={true}
          />
        )}
        <div style={{ overflow: "hidden" }}>
          <PageSEO {...this.state.seo} />
          <Wrapper>
            <Helmet bodyAttributes={{ class: "template" }} />
            <IngredientInfo ingredient={contentfulIngredient} />
            <Benefits ingredient={contentfulIngredient} />
            <IngredientInteractions ingredient={contentfulIngredient} />
            <EvidenceBased ingredient={contentfulIngredient} />
            <Studies ingredient={contentfulIngredient} />
            <ProductCategoryCardsWrapper>
              <ProductCategoryCards className="mb-7 mb-md-9" />
            </ProductCategoryCardsWrapper>
          </Wrapper>
        </div>
      </>
    );
  }
}

export const pageQuery = graphql`
  query IngredientsPageQuery($locale: String!, $slug: String!) {
    contentfulIngredientsPage(node_locale: { eq: $locale }) {
      pageDescription
    }
    contentfulIngredient(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      name
      image {
        title
        gatsbyImageData(layout: CONSTRAINED, width: 570, quality: 90)
        share: resize(width: 1200, height: 630, quality: 100) {
          src
          width
          height
        }
        description
      }
      slug
      productDosages {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      description
      form
      source
      supplier
      foundIn
      function {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      manufacturingLocation
      manufacturerInterview {
        __typename
        title
        slug
      }
      studyCount
      studySubject
      studiesLink
      studies {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      icons {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      interactionsDescription
      interactions
      products {
        __typename
        slug
        name {
          name
          childMarkdownRemark {
            html
          }
        }
        summary
        shortDescription
        cardImage {
          title
          file {
            url
          }
          gatsbyImageData(layout: CONSTRAINED, width: 570, quality: 80)
          description
        }
        cardBackgroundColor
      }
    }
  }
`;
