import { Component } from "react";
import ScrollMagic from "scrollmagic";

export default class ScrollMagicController extends Component {
  constructor(props) {
    super(props);
    this.controller = null;
  }
  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
  }
  render() {
    return null;
  }
}
