import { Component } from "react";
import styled from "styled-components";
import Container from "../Container";
import { responsive, Color, Font } from "../../utils/style";
import Text from "../Text";

const StudiesContainer = styled(Container)`
  margin: 80px auto;

  ${responsive.md`
    margin: 120px auto;
  `}
`;

const SeperatorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Separator = styled.div`
  width: 32px;
  height: 4px;
  background-color: ${Color.ritualBlue};
  margin-bottom: 30px;
`;

const StudyHeader = styled.p`
  color: ${Color.ritualBlue};
  font-size: 22px;
  ${Font.circular} font-weight: 500;
  text-align: center;
  letter-spacing: -0.2px;
  line-height: 32px;
`;

const StudyLink = styled.a.attrs({
  className: "-underline",
})``;

const StudyText = styled.p`
  color: ${Color.ritualBlue};
  font-size: 18px;
  ${Font.dutch} font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
`;

/* eslint-disable react/prefer-stateless-function */
export default class Studies extends Component {
  render() {
    const { studyCount, studySubject, studiesLink } = this.props.ingredient;

    if (!studyCount || !studySubject || !studiesLink) return null;

    return (
      <StudiesContainer>
        <div className="row align-items-center">
          <div className="col-12 col-sm-10 col-lg-8 offset-0 offset-sm-1 offset-lg-2">
            <div className="text-align-center">
              <SeperatorContainer>
                <Separator />
              </SeperatorContainer>
            </div>
            <StudyHeader className="subtitle">
              <Text
                id="ingredients.studies.subtitle"
                defaultMessage="There are {link} on {studySubject} and counting"
                values={{
                  link: (
                    <StudyLink target="_blank" href={studiesLink}>
                      <Text
                        id="ingredients.studies.study-count"
                        defaultMessage="{studyCount} studies"
                        values={{ studyCount }}
                      />
                    </StudyLink>
                  ),
                  studySubject,
                }}
              />
            </StudyHeader>
            <StudyText>
              <Text
                id="ingredients.studies.text"
                defaultMessage={`Substantiation is a living, breathing thing. We are constantly reviewing new research.`}
              />
            </StudyText>
          </div>
        </div>
      </StudiesContainer>
    );
  }
}
