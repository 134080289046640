import { Component } from "react";
import $ from "jquery";
import styled from "styled-components";
import { media, Color, Opacity } from "../../utils/style";

// Animations
import ScrollMagic from "scrollmagic";
import gsap from "gsap";

const CircleElement = styled.div.attrs({ className: "ajs_circle" })`
  position: absolute;
  width: 770px;
  height: 770px;
  background-color: ${Color.ritualYellow};
  border-radius: 50%;
  opacity: 0;
  z-index: 1;
  transition: left 2s, right 2s, opacity 2s;

  &.obscured {
    background-color: #f1de80;
  }

  &.left {
    left: -1540px;
    opacity: 0;
  }

  &.right {
    right: -1540px;
    opacity: 0;
  }

  &.top {
    top: -385px;
    opacity: 0;
  }

  &.topTimeline {
    top: -100px;
    opacity: 0;
  }

  &.featuredArticles {
    opacity: 0;
    z-index: 1;
  }

  &.experts {
    opacity: ${Opacity.light} !important;
    z-index: 0;
  }

  &.bottom {
    bottom: -385px;
    opacity: 0;
  }

  &.centerY {
    top: calc(50% - 385px);
    opacity: 0;
  }

  &.active {
    &.left {
      left: -385px;
      opacity: 1;
    }

    &.right {
      right: -385px;
      opacity: 1;
    }

    &.experts {
      opacity: ${Opacity.light} !important;
    }
  }

  ${media.notDesktop`
      width: 614px;
      height: 614px;

      &.left {
        left: -614px;
      }

      &.right {
        right: -614px;
      }

      &.top {
        top: -307px;
      }

      &.bottom {
        bottom: -307px;
      }

      &.centerY {
        top: calc(50% - 307px);
      }

    &.active {
      &.left {
        left: -307px;
        opacity: 1;
      }

      &.right {
        right: -307px;
        opacity: 1;
      }

      &.experts {
        opacity: ${Opacity.light};
      }
    }
    `}

  ${media.tablet`
      width: 460px;
      height: 460px;

      &.left {
        left: -460px;
      }

      &.right {
        right: -460px;
      }

      &.top {
        top: -230px;
      }

      &.bottom {
        bottom: -230px;
      }

      &.centerY {
        top: calc(50% - 230px);
      }
      &.active {
        &.left {
          left: -230px;
          opacity: 1;
        }

        &.right {
          right: -230px;
          opacity: 1;
        }

        &.experts {
          opacity: ${Opacity.light} !important;
        }
      }
    `}

    ${media.mobile`
      width: 280px;
      height: 280px;

      &.left {
        left: -280px;
      }

      &.right {
        right: -280px;
      }

      &.top {
        top: -140px;
      }

      &.topTimeline {
        top: -140px;
        z-index: 0;
      }

      &.bottom {
        bottom: -140px;
      }

      &.centerY {
        top: -140px;
      }

      &.active {
          &.left {
            left: -140px;
            opacity: 1;
          }

          &.right {
            right: -140px;
            opacity: 1;
          }

          &.experts {
            opacity: ${Opacity.light} !important;
          }
        }
    `}
`;

export default class Circle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementTop: 0,
    };
    this.scene = null;
  }

  componentDidMount() {
    if (this.element) {
      this.setState({
        elementTop: $(this.element).offset().top,
      });
      this.initializeAnimation();
    }
  }

  componentWillUnmount() {
    $(window).off("resize.animationHandler");
  }

  isLeft() {
    let classes = this.props.className;
    return classes.includes("left");
  }

  initializeAnimation() {
    this.resizeAnimation();
    $(window).on("resize.animationHandler", this.resizeAnimation.bind(this));
  }

  resizeAnimation() {
    // If the scene already exists, destory it.
    if (this.scene) {
      this.scene.destroy(true);
    }

    this.scene = new ScrollMagic.Scene({
      triggerElement: this.element,
      offset: -50,
    })
      .setClassToggle(this.element, "active")
      .setTween(this.circleTween())
      .reverse(false)
      .addTo(this.props.controller);
  }

  circleTween() {
    let isLeft = this.isLeft();
    return gsap.fromTo(
      this.element,
      this.generateTweenOptions(isLeft, 0, true),
      this.generateTweenOptions(isLeft, 1, false, 1),
    );
  }

  generateTweenOptions(isLeft, opacity, start, duration) {
    let props = {
      css: {
        opacity: opacity,
      },
    };

    let elementWidth = $(this.element).width();
    let value = start
      ? `-${elementWidth / 2 + 100}px`
      : `-${elementWidth / 2}px`;
    props.css[isLeft ? "left" : "right"] = value;

    if (duration) {
      props.duration = duration;
    }

    return props;
  }

  render() {
    let { scrollTop, className } = this.props;

    return (
      <CircleElement
        ref={(r) => (this.element = r)}
        scrollTop={scrollTop}
        scrollFactor={1}
        className={className}
      />
    );
  }
}
